<template>
  <div class="apply5">
    <van-nav-bar
      title="资料提交"
      :border="false"
      left-arrow
      @click-left="onClickLeft"
    />
    <van-cell-group :border="false">
      <van-cell title-class="biaoti" :border="false" title="确认提交资料" />
      <van-cell title-class="content" :border="false">
        <template #title>
          1.大咖将为您代提交资料给微信支付侧进行签约之前的实名审核
        </template>
      </van-cell>
    </van-cell-group>
    <div class="paytype">
      <van-radio-group v-model="radio">
        <van-cell-group>
          <van-cell>
            <template #icon>
              <van-icon size="24" color="#00C000" name="wechat" />
            </template>
            <template #title>
              微信支付
            </template>
            <template #right-icon>
              <van-radio name="1" checked-color="#00C000" />
            </template>
          </van-cell>
          <van-cell>
            <template #icon>
              <van-icon size="24" color="#FFA000" name="card" />
            </template>
            <template #title>
              银行卡支付
            </template>
            <template #right-icon>
              <van-radio name="2" disabled checked-color="#00C000" />
            </template>
          </van-cell>
        </van-cell-group>
      </van-radio-group>
    </div>
    <van-cell-group :border="false">
      <van-cell title-class="content" :border="false">
        <template #title>
          2.提交直至审核结束前，您将无法修改资料和变更主体
        </template>
      </van-cell>
      <van-cell title-class="content" :border="false">
        <template #title>
          3.若审核通过，您将需要完成微信支付的实名签约。完成整个流程，既确定店铺主体
        </template>
      </van-cell>
      <van-cell title-class="content" :border="false">
        <template #title>
          4.若审核失败，请您配合根据相关提示进行材料的修改和提交
        </template>
      </van-cell>
    </van-cell-group>
    <div class="btn">
      <van-button block type="danger" @click="commition">提交资料</van-button>
    </div>
  </div>
</template>
<script>
export default {
  name: "GonghuoApply5",
  data() {
    return {
      screenWidth: document.body.clientWidth, // 屏幕宽
      screeHeight: document.body.clientHeight, // 屏幕高
      radio: "1",
      shareid: 0,
      merchid: 0,
    }
  },
  mounted() {
    if (this.$route.query.shareid) {
      this.shareid = this.$route.query.shareid
    }else if (localStorage.getItem("DK_MEMBERID")) {
      this.shareid = localStorage.getItem("DK_MEMBERID");
    }
    this.merchid = this.$route.query.merchid ? this.$route.query.merchid : 0;
    this.$parent.getmerchname(this.merchid, "资料提交");
  },
  methods: {
    commition() {
      let _that = null;
      _that = this;
      let gonghuo1 = null;
      gonghuo1 = localStorage.getItem("gonghuo1");
      if (gonghuo1) {
        _that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
        _that.$axios
          .post(
            _that.$store.state.domain + "web/applyshops/update_info",
            gonghuo1
          )
          .then(res => {
            console.log(res);
            if (res.data.code == 100000) {
              _that.$router.push({
                name: "GonghuoApply6",
                query: {
                  shareid: _that.shareid,
                  merchid: _that.merchid,
                  t: new Date().getTime()
                }
              });
            }else {
              _that.$toast(res.data.msg);
            }
          })
          .catch(err => {
            console.log(err);
          })
      }
    },
    onClickLeft() {
      this.$router.back();
    }
  }
}
</script>
<style lang="less">
.apply5 {
  overflow: hidden;
  width: 100%;
  min-height: 100%;
  position: relative;
  z-index: 2;
  background-color: #fff;
  .van-nav-bar .van-icon {
    color: #333;
  }
  .van-cell-group {
    padding: 0;
    .van-cell {
      .van-cell__title {
        flex: 1;
      }
      .biaoti {
        font-size: 20px;
        color: #333;
        font-weight: bold;
        padding: 20px 16px;
      }
      .content {
        font-size: 14px;
        color: #333;
        text-align: left;
      }
    }
  }
  .paytype {
    padding: 16px;
    overflow: hidden;
    .van-radio-group {
      padding: 30px;
      background-color: #ededed;
      border-radius: 2px;
      .van-cell-group {
        overflow: hidden;
        border-radius: 4px;
        .van-cell {
          font-size: 12px;
          line-height: 30px;
          .van-cell__title{
            flex: 1;
            text-align: left;
          }
          .van-icon{
            line-height: 30px;
            margin-right: 10px;
          }
          .van-radio__icon {
            .van-icon {
              line-height: 20px;
            }
          }
        }
      }
    }
  }
  .btn {
    padding: 20px;
    overflow: hidden;
    .van-button {
      margin-top: 20px;
    }
  }
}
</style>